<template >
  <div>
    <vs-table stripe :data="eligibility_time_period_details">
      <template slot="header">
        <div style="display: flex;">
          <vs-button color="primary" icon="arrow_back" @click="$router.push({name:'StudentConnect'})" size="small"></vs-button> &nbsp;
          <h3 style="margin-bottom: 10px;">
            {{ capitalize(eligibility_type) }} [{{eligibility_count}}] | {{start_date}} - {{end_date}}
            <!-- Spoc Summary - {{ summary_details.course }} |  {{ summary_details.city }} | {{start_date}} - {{end_date}} -->

          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th>
          Can Id
        </vs-th>
        <vs-th>
          Candidate Name
        </vs-th>
         <vs-th>
          Spoc Name
        </vs-th>
        <vs-th>
          Level
        </vs-th>
        <vs-th>
          Course
        </vs-th>
         <vs-th>
          Eligibility Date
        </vs-th>
        <vs-th>
          Action
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].identity">
            {{ data[indextr].identity }}
          </vs-td>

          <vs-td :data="data[indextr].person_name">
            {{ data[indextr].person_name }}
          </vs-td>
           <vs-td :data="data[indextr].spoc_name">
            {{ data[indextr].spoc_name }}
          </vs-td>
          <vs-td :data="data[indextr].level">
            {{ data[indextr].level }}
          </vs-td>
          <vs-td :data="data[indextr].courses">
            {{ data[indextr].courses }}
          </vs-td>
            <vs-td :data="data[indextr].eligibility_date">
            {{ data[indextr].eligibility_date }}
          </vs-td>
          <vs-td :data="data[indextr].identity">
            <div @click="openPopupEmit(data[indextr])">
              <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- <vs-pagination style="padding-top: 1%;" :total="total_pages" v-model="page_no"></vs-pagination> -->
    <vs-pagination style="padding-top: 1%;" :total="total_pages" v-model="page_no"></vs-pagination>

  </div>
</template>

<script>
// import axios from "axios";
// import constants from "../../../constants.json";
import ApiServices from "../../ApiServices";
export default {
  mounted() {
    this.eligibility_type = this.$route.params.eligibility_time_period_detail.eligibility_type
    this.eligibility_count = this.$route.params.eligibility_time_period_detail.eligibility_count
    this.start_date = this.$route.params.eligibility_time_period_detail.start_date
    this.end_date = this.$route.params.eligibility_time_period_detail.end_date
    this.timeperiodEligibilityDetails(this.page_no);
  },
  watch: {
    page_no(value) {
      this.timeperiodEligibilityDetails(value);
    },
  },
  data: () => ({
    eligibility_header: "",
    eligibility_time_period_details: [],
    eligibility_type: '',
    eligibility_count:'',
    start_date:'',
    end_date:'',
    page_no:1,
    total_pages: 1,
  }),
  methods: {
    timeperiodEligibilityDetails(page) {
      this.$vs.loading();
      // let url = `${constants.MILES_DASHBOARDS}evaluationDetails?evaluation_type=${this.evaluation_type}&page=${page}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //   })
        ApiServices.timeperiodEligibilityDetails(this.eligibility_type,this.start_date,this.end_date,page)
        .then((response) => {
          this.eligibility_time_period_details = response.data.data
          this.total_pages = response.data.last_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    capitalize(val) {
            const str = val.replace(/_/g, " ");
            const arr = str.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

            }
            const str2 = arr.join(" ");
            return str2

        },
     openPopupEmit(payload) {
      console.log("payload", payload);
      this.openBigPopup(payload.mwb_id);
    }
  }
}
</script>